<template>

  <section class="bg-white dark:bg-gray-Aprenda Do caos900">
    <div class="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
      <div class="mr-auto place-self-center lg:col-span-7">
        <h1
          class="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-white">
          Bem-vindo a Mentoria para acelerar sua carreira DEV!</h1>
        <p class="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
          O Laboratório Dev é um programa intensivo de mentoria com duração de 6 meses projetado para capacitar pessoas
          em transição de carreira ou
          iniciando os estudos em desenvolvimento de software. Nossa missão é fornecer uma base sólida em tecnologias
          essenciais
          para desenvolvimento web, tanto em frontend quanto em backend, além de bancos de dados.
        </p>
      </div>
      <div class="hidden lg:mt-0 lg:col-span-5 lg:flex">
        <img src="hero.png" alt="hero image">
      </div>
    </div>
  </section>

  <!-- LOGOMARCAS -->
  <section class="bg-white dark:bg-gray-900">
    <div class="max-w-screen-xl px-4 pb-8 mx-auto lg:pb-16">
      <div class="grid grid-cols-2 gap-8 text-gray-500 sm:gap-12 sm:grid-cols-3 lg:grid-cols-6 dark:text-gray-400">
        <img class="flex items-center lg:justify-center" src="mysql.png" />
        <img class="flex items-center lg:justify-center" src="javascirpt.png" />
        <img class="flex items-center lg:justify-center" src="typescript.png" />
        <img class="flex items-center lg:justify-center" src="vue.png" />
        <img class="flex items-center lg:justify-center" src="nest.png" />
        <img class="flex items-center lg:justify-center" src="cypress.png" />
      </div>
    </div>
  </section>

  <section class="bg-gray-50 dark:bg-gray-800">
    <div class="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">

      <!-- Row 1 -->
      <div class="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
        <div class="text-gray-500 sm:text-lg dark:text-gray-400">
          <h2 class="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
            Aprenda fazendo
          </h2>
          <p class="mb-8 font-light lg:text-xl">

          </p>
          <!-- List -->
          <ul role="list" class="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
            <li class="flex space-x-3">
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"></path>
              </svg>
              <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                Mais próximo do mundo real: Você receberá demandas para desenvolver como se estivesse trabalhando em uma
                empresa.
              </span>
            </li>
            <li class="flex space-x-3">
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"></path>
              </svg>
              <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                Programação em par/grupo: Você vai debater com o time e outros desenvolvedores como implementar a melhor
                solução com o auxílio do seu mentor.
              </span>
            </li>
            <li class="flex space-x-3">
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"></path>
              </svg>
              <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                Chega de só assistir aulas gravadas: Tenha um acompanhamento ao vivo e dedicado e acelere seu
                aprendizado.
              </span>
            </li>
          </ul>
          <p class="mb-8 font-light lg:text-xl">
            Tenha uma experiência única com esta metodologia do Laboratório DEV.
          </p>
        </div>
        <img class="hidden w-full mb-4 rounded-lg lg:mb-0 lg:flex" src="home0.png" alt="dashboard feature image">
      </div>

      <!-- Row 2 -->
      <div class="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
        <img class="hidden w-full mb-4 rounded-lg lg:mb-0 lg:flex" src="home2.png" alt="feature image 2">
        <div class="text-gray-500 sm:text-lg dark:text-gray-400">
          <h2 class="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
            Essas são algumas ferramentas e técnicas que você vai aprender:
          </h2>
          <p class="mb-8 font-light lg:text-xl">
            Além de aprender os fundamentos, você vai aprender a usar algumas técnicas e ferramentas
          </p>
          <!-- List -->
          <ul role="list" class="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
            <li class="flex space-x-3">
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"></path>
              </svg>
              <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                Como analisar requisições e respostas em integrações entre sistemas via API com o postman
              </span>
            </li>

            <li class="flex space-x-3">
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"></path>
              </svg>
              <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                Aprender a criar banco de dados, armazenar e gerenciar informações
              </span>
            </li>

            <li class="flex space-x-3">
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"></path>
              </svg>
              <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                Como analisar códigos e identificar possíveis falhas utilizando debug
              </span>
            </li>

            <li class="flex space-x-3">
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"></path>
              </svg>
              <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                Como se conectar a banco de dados e realizar operações de consulta, cadastro, edição e exclusão de dados
                com DBeaver
              </span>
            </li>

            <li class="flex space-x-3">
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"></path>
              </svg>
              <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                Como analisar requisições e respostas em integrações entre sistemas via API com o postman
              </span>
            </li>

            <li class="flex space-x-3">
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"></path>
              </svg>
              <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                Como analisar códigos e identificar possíveis falhas utilizando debug
              </span>
            </li>

          </ul>
          <p class="font-light lg:text-xl">
            Utilizamos metodologias ágeis de aprendizagem e de desenvolvimento
          </p>
        </div>
      </div>

      <!-- Row 3 -->
      <div class="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
        <div class="text-gray-500 sm:text-lg dark:text-gray-400">
          <h2 class="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
            Você vai aprender
          </h2>
          <p class="mb-8 font-light lg:text-xl">

          </p>
          <!-- List -->
          <ul role="list" class="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
            <li class="flex space-x-3">
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"></path>
              </svg>
              <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                COMO FUNCIONA o processo de desenvolvimento de um software
              </span>
            </li>
            <li class="flex space-x-3">
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"></path>
              </svg>
              <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                INTERPRETAR requisitos de usuários e converter em funcionalidades de um software
              </span>
            </li>
            <li class="flex space-x-3">
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"></path>
              </svg>
              <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                DESENVOLVER sua capacidade de raciocínio orientado à resolução de problemas e construção de soluções
              </span>
            </li>
          </ul>
          <p class="mb-8 font-light lg:text-xl">
            Dê o primeiro passo para transformar sua carreira e tornar-se um desenvolvedor de sucesso. Junte-se ao
            Laboratório Dev e
            comece sua jornada hoje mesmo!
          </p>
        </div>
        <img class="hidden w-full mb-4 rounded-lg lg:mb-0 lg:flex" src="home1.png" alt="dashboard feature image">
      </div>
    </div>
  </section>


  <section class="bg-white dark:bg-gray-900">
    <div
      class="items-center max-w-screen-xl px-4 py-8 mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:py-24 lg:px-6">
      <div class="col-span-2 mb-8">
        <p class="text-lg font-medium text-purple-600 dark:text-purple-500">Do caos à clareza</p>
        <h2 class="mt-3 mb-4 text-3xl font-extrabold tracking-tight text-gray-900 md:text-3xl dark:text-white">
          Seu mentor possui mais de 20 anos de experiência no desenvolvimento de software
        </h2>
        <p class="font-light text-gray-500 sm:text-xl dark:text-gray-400">
          Com uma didática próxima ao dia a dia de um profissional da área de desenvolvimento, você vai aprender
          enquanto
          desenvolve projetos utilizando Kanban e outras metodologias e práticas do mercado.
        </p>
        <div class="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
          <div>
            <a target="_blank" href="https://www.linkedin.com/in/yonatha/"
              class="inline-flex items-center text-base font-medium text-purple-600 hover:text-purple-800 dark:text-purple-500 dark:hover:text-purple-700">
              Acesse o linkedin do seu mentor
              <svg class="w-5 h-5 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="col-span-2 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0">
        <div>
          <svg class="w-10 h-10 mb-2 text-purple-600 md:w-12 md:h-12 dark:text-purple-500" fill="currentColor"
            viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M2 5a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm14 1a1 1 0 11-2 0 1 1 0 012 0zM2 13a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2zm14 1a1 1 0 11-2 0 1 1 0 012 0z"
              clip-rule="evenodd"></path>
          </svg>
          <h3 class="mb-2 text-2xl font-bold dark:text-white">96 horas</h3>
          <p class="font-light text-gray-500 dark:text-gray-400">
            2 Encontros por semana com duração de 2h
          </p>
        </div>
        <div>
          <svg class="w-10 h-10 mb-2 text-purple-600 md:w-12 md:h-12 dark:text-purple-500" fill="currentColor"
            viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z">
            </path>
          </svg>
          <h3 class="mb-2 text-2xl font-bold dark:text-white">Aprendizado colaborativo</h3>
          <p class="font-light text-gray-500 dark:text-gray-400">
            Muita prática em grupo e individual
          </p>
        </div>
        <div>
          <svg class="w-10 h-10 mb-2 text-purple-600 md:w-12 md:h-12 dark:text-purple-500" fill="currentColor"
            viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z"
              clip-rule="evenodd"></path>
          </svg>
          <h3 class="mb-2 text-2xl font-bold dark:text-white">Grupo exclusivo</h3>
          <p class="font-light text-gray-500 dark:text-gray-400">
            Servidor no discord e grupo no whastapp
          </p>
        </div>
      </div>
    </div>
  </section>





  <section class="bg-gray-50 dark:bg-gray-800" style="display: none;">
    <div class="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-24 lg:px-6">
      <figure class="max-w-screen-md mx-auto">
        <svg class="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600" viewBox="0 0 24 27" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
            fill="currentColor" />
        </svg>
        <blockquote>
          <p class="text-xl font-medium text-gray-900 md:text-2xl dark:text-white">
            "Depoimento1"
          </p>
        </blockquote>
        <figcaption class="flex items-center justify-center mt-6 space-x-3">
          <!-- img class="w-6 h-6 rounded-full" src="" alt="profile picture" -->
          <div class="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
            <div class="pr-3 font-medium text-gray-900 dark:text-white">Nome</div>
            <div class="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">
              Profissão
            </div>
          </div>
        </figcaption>
      </figure>
    </div>
  </section>


  <section class="bg-white dark:bg-gray-900">
    <div id="inscricao" class="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
      <div class="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
        <h2 class="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
          Dê o primeiro passo para transformar sua carreira e tornar-se um desenvolvedor de sucesso. Junte-se ao
          Laboratório Dev e
          comece sua jornada hoje mesmo!
        </h2>
        <p class="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">H</p>
      </div>
      <div class="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
        <!-- Pricing Card -->


        <div class=" flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100
          rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
          <h3 class="mb-4 text-2xl font-semibold">Gratuito</h3>
          <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
            Junte-se a comunidade
          </p>
          <div class="flex items-baseline justify-center my-8">
            <span class="mr-2 text-5xl font-extrabold">R$ 0,00</span>
            <span class="text-gray-500 dark:text-gray-400">/mês</span>
          </div>
          <!-- List -->
          <ul role="list" class="mb-8 space-y-4 text-left">
            <li class="flex items-center space-x-3">
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"></path>
              </svg>
              <span>Acesso a comunidade no Discord</span>
            </li>
            <li class="flex items-center space-x-3">
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"></path>
              </svg>
              <span>Participação de eventos online no servidor</span>
            </li>
            <li class="flex items-center space-x-3">
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"></path>
              </svg>
              <span>
                Networking
              </span>
            </li>
            <li class="flex items-center space-x-3">
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"></path>
              </svg>
              <span>
                Curadoria de vagas/oportunidades
              </span>
            </li>
          </ul>
          <a target="_blank" href="https://discord.gg/fayNQRt6p3"
            class="text-white bg-purple-600 hover:bg-purple-700 focus:ring-4 focus:ring-purple-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-purple-900">
            Entre na comunidade
          </a>
        </div>


        <div
          class="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
          <h3 class="mb-4 text-2xl font-semibold">Aulas</h3>
          <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
            Tenho um apoio profissional para acelerar seu aprendizado
          </p>
          <div class="flex items-baseline justify-center my-8">
            <span class="mr-2 text-5xl font-extrabold">R$ 100,00</span>
            <span class="text-gray-500 dark:text-gray-400">/mês</span>
          </div>
          <!-- List -->
          <ul role="list" class="mb-8 space-y-4 text-left">
            <li class="flex items-center space-x-3">
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"></path>
              </svg>
              <span>Aulas ao vivo com 2 encontros por semana 2h de duração</span>
            </li>
            <li class="flex items-center space-x-3">
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"></path>
              </svg>
              <span>Code review</span>
            </li>
            <li class="flex items-center space-x-3">
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"></path>
              </svg>
              <span>Grupo exclusivo no whastapp</span>
            </li>
            <li class="flex items-center space-x-3">
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"></path>
              </svg>
              <span>
                Acesso a aulas gravadas
              </span>
            </li>
            <li class="flex items-center space-x-3">
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"></path>
              </svg>
              <span>Suporte a resolução de problemas</span>
            </li>
            <li class="flex items-center space-x-3">
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"></path>
              </svg>
              <span>
                Mentoria de carreira
              </span>
            </li>
          </ul>
          <a target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLSdTVmAxvWgl67IDN1RT3DvadaA7qn9wtPsNmjbZxZmRknnbjQ/viewform?usp=sf_link"
            class="text-white bg-purple-600 hover:bg-purple-700 focus:ring-4 focus:ring-purple-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-purple-900">
            Faça sua inscrição
          </a>
        </div>



        <div
          class="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
          <h3 class="mb-4 text-2xl font-semibold">Mentoria individual</h3>
          <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
            Tenho um apoio individual e exclusivo para acelerar seu aprendizado
          </p>
          <div class="flex items-baseline justify-center my-8">
            <span class="mr-2 text-5xl font-extrabold">R$ 75,00</span>
            <span class="text-gray-500 dark:text-gray-400">/hora</span>
          </div>
          <!-- List -->
          <ul role="list" class="mb-8 space-y-4 text-left">
            <li class="flex items-center space-x-3">
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"></path>
              </svg>
              <span>Aula individual</span>
            </li>
            <li class="flex items-center space-x-3">
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"></path>
              </svg>
              <span>Code review</span>
            </li>

            <li class="flex items-center space-x-3">
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"></path>
              </svg>
              <span>Grupo exclusivo no whastapp</span>
            </li>
            <li class="flex items-center space-x-3">
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"></path>
              </svg>
              <span>
                Auxilio no desenvolvimento da arquitetura de software
              </span>
            </li>
            <li class="flex items-center space-x-3">
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"></path>
              </svg>
              <span>Suporte a resolução de problemas</span>
            </li>
            <li class="flex items-center space-x-3">
              <!-- Icon -->
              <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"></path>
              </svg>
              <span>
                Mentoria de carreira backend
              </span>
            </li>
          </ul>
          <a target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLSdTVmAxvWgl67IDN1RT3DvadaA7qn9wtPsNmjbZxZmRknnbjQ/viewform?usp=sf_link"
            class="text-white bg-purple-600 hover:bg-purple-700 focus:ring-4 focus:ring-purple-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-purple-900">
            Faça sua inscrição
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<style scoped></style>
