<template>
  <Cabecalho />
  <Home />
  <Rodape />
</template>

<script>
import Home from './components/Home.vue'
import Cabecalho from './components/Cabecalho.vue'
import Rodape from './components/Rodape.vue'

export default {
  name: 'App',
  components: {
    Home,
    Cabecalho,
    Rodape
  }
}
</script>

<style>
#app {}
</style>
